.KringliedPage-section1{
    padding: 100px 10%;
    height: 670px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    align-content: center;
}

.KringliedPage-section1-mobile{
    padding:10% 10%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    align-content: center;
    align-items: center;
}


.KringliedPage-section1-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.KringliedPage-section1-textBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40vw;
    top: 50%;
}

.KringliedPage-section1-textBox-mobile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    top: 50%;
}

.KringliedPage-section1-lyricsBox{
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    padding: 40px;
    clip-path: polygon(
                    0 0,
                    100% 2vw,
                    100% 100%,
                    0 calc(100% - 2vw)
    );
    border: $gold solid 10px;
    width: fit-content;
    z-index: 2;
    position: absolute;
    right: 10%;
    top: 100px;
}

.KringliedPage-section1-lyricsBox-mobile{
    margin-top: 50px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    padding: 40px;
    clip-path: polygon(
                    0 0,
                    100% 2vw,
                    100% 100%,
                    0 calc(100% - 2vw)
    );
    border: $gold solid 10px;
    width: fit-content;
    align-self: center;
}

.KringliedPage-section1-alineaBox{
    margin-bottom: 50px;
}

.KringliedPage-section1-topKringBox{
}

.KringliedPage-section1-alineaBox:after{
    margin-bottom: 50px;
}
.KringliedPage-section1-musictitel{
    padding-top: 50px;
    text-align: center;
    align-self: center;
}

.KringliedPage-section2{
    clip-path: polygon(
                    0 0,
                    100% 6vw,
                    100% 100%,
                    0 calc(100% - 3vw)
    );
    background: $primary;
    padding: 10vh 10%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    min-height: 1500px !important;
}

.KringliedPage-section2-mobile{
    clip-path: polygon(
                    0 0,
                    100% 6vw,
                    100% 100%,
                    0 calc(100% - 3vw)
    );
    background: $primary;
    padding: 10vh 10%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    min-height: 100vh;
}

.KringliedPage-section2-textBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    width: 40vw;
    top: 50%;
    text-align: left;
}

.KringliedPage-section2-textBox-mobile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    top: 50%;
    text-align: left;

}

.KringliedPage-section2-imageBox{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 70px;
    width: 60vw;
    top: 50%;
    text-align: left;
    margin-bottom: 150px;
    flex-wrap: wrap;
}

.KringliedPage-section2-imageBox-mobile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 70px;
    top: 50%;
    text-align: left;
    margin-bottom: 150px;
    flex-wrap: wrap;
}

.KringliedPage-partituur{
    height: 60vh;
    border: 5px solid $gold;
    z-index: 2;
    margin: 10px  10px;
}

.KringliedPage-section3{
    clip-path: polygon(
                    0 0,
                    100% 6vw,
                    100% 100%,
                    0 calc(100% - 3vw)
    );
    background: $paper;
    padding: 10vh 10%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-align: center;
    min-height: 100vh;
}

.KringliedPage-section3-mobile{
    clip-path: polygon(
                    0 0,
                    100% 6vw,
                    100% 100%,
                    0 calc(100% - 3vw)
    );
    background: $paper;
    padding: 10vh 10%;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.KringliedPage-section3-textBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    width: 40vw;
    top: 50%;
    text-align: left;
}

.KringliedPage-section3-textBox-mobile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    top: 50%;
    text-align: left;
}

.KringliedPage-section3-songBox{
    margin-top: 50px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    padding: 40px 40px 40px 40px;
    clip-path: polygon(
                    0 0,
                    100% 2vw,
                    100% 100%,
                    0 calc(100% - 2vw)
    );
    border: $gold solid 10px;
    width: fit-content;
    z-index: 2;
    margin-right: 50px;
}

.KringliedPage-section3-songBox-mobile{
    margin-top: 50px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    padding: 40px 40px 40px 40px;
    clip-path: polygon(
                    0 0,
                    100% 2vw,
                    100% 100%,
                    0 calc(100% - 2vw)
    );
    border: $gold solid 10px;
    width: fit-content;
    z-index: 2;
}

.KringliedPage-section3-alineaBox{
    margin-bottom: 30px;
}

.KringliedPage-section3-refreinBox{
    margin-bottom: 30px;
    margin-left: 30px;
    width: fit-content;
}

.KringliedPage-section3-imageBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.KringliedPage-section3-image{
    margin-top: 50px;
    width: 30vw;
    border: 5px solid $gold;
    z-index: 2;
    align-self: center;
}

.KringliedPage-section3-subText{
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    align-self: center;
}