.btn-full{
  display:inline-block;
  padding:0.2em 0.5em;
  margin:0 0.1em 0.1em 0;
  border:0.05em solid rgba(255,255,255,0);
  border-radius: 3px;
  box-sizing: border-box;
  text-decoration:none;
  color:$black;
  text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
  text-align:center;
  transition: all 0.2s;
}

@media all and (max-width:30em){
  .btn-full{
    display:block;
    margin:0.2em auto;
    }
  .btn-outline{
    display:block;
    margin:0.2em auto;
  }
}

.btn-outline{
  display:inline-block;
  padding:0.2em 0.5em;
  margin:0 0.1em 0.1em 0;
  border:0.1em solid rgba(255,255,255,0);
  border-radius: 3px;
  box-sizing: border-box;
  text-decoration:none;
  color:#FFFFFF;
  text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
  text-align:center;
  transition: all 0.2s;
}

.btn-full.btn-primary{
  background-color: $primary
}

.btn-full.btn-primary:hover{
  color: $white
}

.btn-outline.btn-primary{
  background-color: transparent;
  border-color: $primary;
  color: $primary;
}

.btn-outline.btn-primary:hover{
  border-color: transparent;
  color: $white;
  background-color: $primary;
}

.btn-full.btn-gray{
  background-color: $gray
}

.btn-full.btn-gray:hover{
  color: $white
}

.btn-outline.btn-gray{
  background-color: transparent;
  border-color: $gray;
  color: $gray;
}

.btn-outline.btn-gray:hover{
  border-color: transparent;
  color: $white;
  background-color: $gray;
}

.btn-full.btn-dark-gray{
  background-color: $dark-gray;
  color: $paper;
}

.btn-full.btn-dark-gray:hover{
  color: $white
}

.btn-outline.btn-dark-gray{
  background-color: transparent;
  border-color: $dark-gray;
  color: $dark-gray;
}

.btn-outline.btn-dark-gray:hover{
  border-color: transparent;
  color: $white;
  background-color: $dark-gray;
}

.btn-full.btn-primary-light{
  background-color: $primary-light
}

.btn-outline.btn-primary-light{
  background-color: transparent;
  border-color: $primary-light;
  color: $primary-light;
}

.btn-outline.btn-primary-light:hover{
  border-color: transparent;
  color: $white;
  background-color: $primary-light;
}
