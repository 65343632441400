//Not gonna lie. This is mostly copy and pasted from an example on the following site with some adjustments:
// https://codesandbox.io/s/framer-motion-animatesharedlayout-app-store-demo-i1kct?from-embed=&file=/src/Item.js:581-605
// Not the prettiest way to do it but it saves time.

.card {
  padding: 25px;
  width: 460px;
  display: flex;
  flex-direction: column;
}

.card-content-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  pointer-events: none;
}

.card-content-container.open {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 2;
  overflow: hidden;
  padding: 40px 0;
}

.card-content {
  pointer-events: auto;
  position: relative;
  background: $paper;
  overflow-x: hidden;
  overflow-y: hidden ;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 4px;
}

//.open .card-content::-webkit-scrollbar {
//  width: 12px;
//}
//
//.open .card-content::-webkit-scrollbar-track {
//  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//  border-radius: 10px;
//}
//
//.open .card-content::-webkit-scrollbar-thumb {
//  border-radius: 10px;
//  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
//}

.open .card-content {
  height: auto;
  max-width: 700px;
  overflow-y: scroll;
  overflow-x: hidden ;
  scroll-behavior: smooth;
  max-height: calc(100vh - 80px);
  pointer-events: auto
}

.open-mobile .card-content{
  height: auto;
  max-width: 90vw;
  overflow-y: scroll;
  overflow-x: hidden ;
  scroll-behavior: smooth;
  max-height: calc(100vh - 80px);
  pointer-events: auto
}

.card-open-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card-image-container {
  padding-top: 40px;
  //height: 420px;
  //width: 420px;
  display: flex;
  justify-content: center;
}

.card-image{
  width: 100%;
}

.card-top{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.open .card-image-container,
.open .title-container {
  z-index: 1;
}

.category {
  text-transform: uppercase;
}

.content-container {
  height: 100%;
  margin: 0 40px 40px 40px;
}

.title-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  bottom: 0;
  max-width: 300px;
  padding-left: 10px;
  text-align: center;
}

.title-container2{
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 300px;
  padding-left: 20px;
}

.open .title-container {
  top: 30px;
  left: 30px;
}

.avatar {
  background: var(--divider);
  border-radius: 50%;
  position: absolute;
  bottom: 12px;
  right: 0;
  overflow: hidden;
}

.avatar,
.avatar img {
  width: 40px;
  height: 40px;
}

.overlay {
  z-index: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
}

.PraesidiumItem-button{
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: fixed;
  top: 45px;
  right: calc(calc(100vw - 700px)/2);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

@media only screen and (max-width: 600px) {
  .card {
    flex: 1 0 100%;
    max-width: 100%;
  }
}