
.HomePage-picture{
  background-image: url("https://images.diana.be/diana_website/HomePicture.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100vw;
  top: 0;
  z-index: 0;
  height: 100vh;
  width: 100%;
  position: absolute
}






