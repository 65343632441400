.Page-root{
  background-color: $paper;
  height: 100%;
  width: 100%;
  overflow-x: hidden !important;
}

.Page-section{
  overflow-x: hidden;
  width: 100vw;
}
