.rhap_main{
  flex-direction: row-reverse !important;
}

.rhap_additional-controls{
  flex: none !important;
}

.rhap_volume-controls{
  justify-content: space-around;
}

.rhap_stacked .rhap_controls-section{
  margin-top: 0 !important;
}

.rhap_container{
  background-color: transparent;
  box-shadow: none;
  border-bottom: $primary solid 1px;
}