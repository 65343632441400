.MenuItemMobile-link{
  display: flex;
  color: $primary;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 2.5rem;
  z-index: 5;
  overflow-x: hidden;
  &:hover {
    border-left: 4px solid $gold;
    cursor: pointer;
  };
}

.MenuItemMobile-div {
  display: flex;
  align-items: center;
}
.MenuItemMobile-item{
  font-size: 2.5rem;
  font-weight: bold;
  color: $primary-light;
  z-index: 5;
  width: 100%;
  pointer-events: auto;
}

.MenuItemMobile-dropdownLink{
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $dark-gray;
  font-size: 18px;
  &:hover {
    border-left: 4px solid $gold;
    color: $primary;
    cursor: pointer;
  }
}
