.GeschiedenisPage-section1{
  padding:10% 10%;
  height: 80vh;
  display: flex;
  flex-direction: row;
  z-index: 1;
  align-content: space-between;
  justify-content: space-between;
}

.GeschiedenisPage-section1-mobile{
  padding: 10% 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.GeschiedenisPage-textBox-left1{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40vw;
  top: 50%;
  text-align: left;
}

.GeschiedenisPage-textBox-left1-mobile{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80vw;
  top: 50%;
  text-align: left;
}

.GeschiedenisPage-textBox-left{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40vw;
  top: 50%;
  text-align: left;
  margin: 100px 0;
}

.GeschiedenisPage-textBox-left-mobile{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80vw;
  top: 50%;
  text-align: left;
  margin: 50px 0;
}

.GeschiedenisPage-textBox-right{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 40vw;
  top: 50%;
  margin: 100px 0;
}

.GeschiedenisPage-textBox-right-mobile{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 80vw;
  top: 50%;
  margin: 50px 0;
}

.GeschiedenisPage-imageBox-left{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40vw;
  top: 50%;
  margin: 100px 0;
  justify-content: center;
}

.GeschiedenisPage-imageBox-left-mobile{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  height: auto;
  top: 50%;
  margin: 50px 0;
  justify-content: center;
}

.GeschiedenisPage-imageBox-right{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: left;
  width: 40vw;
  top: 50%;
  margin: 100px 0;
}

.GeschiedenisPage-imageBox-right-mobile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  top: 50%;
  margin: 50px 0;
  max-height: 50%;
}

.GeschiedenisPage-imageBox-subBox{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  width: 40vw;
  height: 45vh;
  overflow: hidden;
}

.GeschiedenisPage-imageBox-subBox-mobile{
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 80vw;
}

.GeschiedenisPage-subBox-image{
  height: 40vh;
  border: 5px solid $gold;
  z-index: 3;
  margin: 10px  10px;
  position: absolute;
  right: 5vw;
}

.GeschiedenisPage-subBox-image-mobile{
  height: 40vh;
  border: 5px solid $gold;
  z-index: 3;
  margin: 10px  10px;
  right: 5vw;
}

.GeschiedenisPage-subBox-image2{
  height: 40vh;
  border: 5px solid $gold;
  z-index: 2;
  margin: 10px  10px;
  position: absolute;
  right: 10vw;
  overflow: hidden;
}

.GeschiedenisPage-subBox-image2-mobile{
  border: 5px solid $gold;
  max-height: 50vh;
  width: auto;
  z-index: 2;
  margin: 10px  10px;
  overflow: hidden;
}

.GeschiedenisPage-image{
  height: 40vh;
  border: 5px solid $gold;
  z-index: 2;
  margin: 10px  10px;
}

.GeschiedenisPage-image-mobile{
  width: auto;
  max-width: 80vw;
  max-height: 60vh;
  border: 5px solid $gold;
  z-index: 2;
  margin: 10px  10px;
  justify-content: center;
}

.GeschiedenisPage-section2{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $primary;
  padding: 10vh 10%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.GeschiedenisPage-section2-mobile{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $primary;
  padding: 10vh 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.GeschiedenisPage-spacing{
  margin-top: 100px;
}

.GeschiedenisPage-schild{
  width: 25vw;
}

.GeschiedenisPage-schild-mobile{
  max-height: 75vh;
  width: auto;
}

.GeschiedenisPage-section3{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $paper;
  padding: 10vh 10%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.GeschiedenisPage-section3-mobile{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $paper;
  padding: 10vh 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.GeschiedenisPage-image-subText-right{
  align-self: flex-end;
  font-size: 14px;
  text-align: center;
}

.GeschiedenisPage-image-subText-right-mobile{
  margin-top: 25px;
  align-self: center;
  font-size: 14px;
  text-align: center;
}

.GeschiedenisPage-image-subText-left{
  align-self: flex-start;
  font-size: 14px;
  text-align: left;
  margin-left: 5px;
}

.GeschiedenisPage-image-subText-left-mobile{
  align-self: center;
  font-size: 14px;
  text-align: left;
  margin-left: 5px;
}

.GeschiedenisPage-section4{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $primary;
  padding: 10vh 10%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.GeschiedenisPage-section4-mobile{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $primary;
  padding: 10vh 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.GeschiedenisPage-section4-box{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.GeschiedenisPage-section4-box-mobile{
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.GeschiedenisPage-section4-box-mobile2{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.GeschiedenisPage-section5{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $paper;
  padding: 10vh 10%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.GeschiedenisPage-section5-mobile{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $paper;
  padding: 10vh 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.GeschiedenisPage-section5-praesides{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center;
  padding: 40px 0;
  clip-path: polygon(
                  0 0,
                  100% 2vw,
                  100% 100%,
                  0 calc(100% - 2vw)
  );
  border: $gold solid 10px;
}

.GeschiedenisPage-divider{
   height: 50px;
}

.GeschiedenisPage-finalImages-mobile{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: auto;
  top: 50%;
  margin: 50px 0;
}
