.Typography-white{
  color: $white;
}

.Typography-black{
  color: $black;
}

.Typography-red{
  color: $primary;
}

.Typography-alinea{
  margin-top: 20px;
}

.Typography-bold{
  font-weight: bold;
}

.Typography-italic{
  font-style: italic;
}

a{
  font-family: Raleway-regular, sans-serif;
  font-size: 18px;
  color: $gold;
}

a:hover{
  cursor: pointer;
}

p {
  font-family: Raleway-regular, sans-serif;
  font-size: 18px;
}

h1 {
  font-size: 64px;
  font-family: Raleway-Bold, sans-serif;
  word-break: break-word;
}
h2 {
  font-size: 36px;
  font-family: Raleway-Bold, sans-serif;
  word-break:  break-word;
}
h3 {
  font-size: 34px;
  font-family: Raleway-regular, sans-serif;
  word-break: break-word;
}
h4 {
  font-size: 22px;
  font-family: Raleway-regular, sans-serif;
  word-break: break-word;
}


@font-face {
  font-family: "Raleway-Bold";
  src: url("../../../src/fonts/Raleway-Bold.ttf") format('truetype');
  font-display: swap;
}
@font-face {
  font-family: "Raleway-regular";
  src: url("../../../src/fonts/Raleway-Regular.ttf") format('truetype');
  font-display: swap;
}
@font-face {
  font-family: "Raleway-Thin";
  src: url("../../../src/fonts/Raleway-Thin.ttf") format('truetype');
  font-display: swap;
}
@font-face {
  font-family: "LeagueSpartan-Bold";
  src: url("../../../src/fonts/LeagueSpartan-Bold.otf") format('opentype');
  font-display: swap;
}

@font-face {
  font-family: "LemonMilk-Bold";
  src: url("../../../src/fonts/LEMONMILK-Bold.otf") format('opentype');
  font-display: swap;
}

@font-face {
  font-family: "LemonMilk-BoldItalic";
  src: url("../../../src/fonts/LEMONMILK-BoldItalic.otf") format('opentype');
  font-display: swap;
}

@font-face {
  font-family: "LemonMilk-Light";
  src: url("../../../src/fonts/LEMONMILK-Light.otf") format('opentype');
  font-display: swap;
}

@font-face {
  font-family: "LemonMilk-LightItalic";
  src: url("../../../src/fonts/LEMONMILK-LightItalic.otf") format('opentype');
  font-display: swap;
}

@font-face {
  font-family: "LemonMilk-Medium";
  src: url("../../../src/fonts/LEMONMILK-Medium.otf") format('opentype');
  font-display: swap;
}

@font-face {
  font-family: "LemonMilk-MediumItalic";
  src: url("../../../src/fonts/LEMONMILK-MediumItalic.otf") format('opentype');
  font-display: swap;
}

@font-face {
  font-family: "LemonMilk-regular";
  src: url("../../../src/fonts/LEMONMILK-Regular.otf") format('opentype');
  font-display: swap;
}

@font-face {
  font-family: "LemonMilk-regularItalic";
  src: url("../../../src/fonts/LEMONMILK-RegularItalic.otf") format('opentype');
  font-display: swap;
}
