.InternReglementPage-section1{
  padding:10% 10%;
  height: 80vh;
  display: flex;
  flex-direction: row;
  z-index: 1;
  align-content: space-between;
  justify-content: space-between;
}

.InternReglementPage-section1-mobile{
  padding: 10% 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.InternReglementPage-textBox-left1{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40vw;
  top: 50%;
  text-align: left;
}

.InternReglementPage-textBox-left1-mobile{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80vw;
  top: 50%;
  text-align: left;
}

.InternReglementPage-textBox-left{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40vw;
  top: 50%;
  text-align: left;
  margin: 100px 0;
}

.InternReglementPage-textBox-left-mobile{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80vw;
  top: 50%;
  text-align: left;
  margin: 50px 0;
}

.InternReglementPage-textBox-right{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 40vw;
  top: 50%;
  margin: 100px 0;
}

.InternReglementPage-textBox-right-mobile{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 80vw;
  top: 50%;
  margin: 50px 0;
}


.InternReglementPage-spacing{
  margin-top: 100px;
}

.InternReglementPage-schild{
  width: 25vw;
}

.InternReglementPage-schild-mobile{
  width: 50vw;
}

.InternReglementPage-section3{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $paper;
  padding: 10vh 10%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.InternReglementPage-section3-mobile{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $paper;
  padding: 10vh 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.InternReglementPage-section4{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $primary;
  padding: 10vh 10%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.InternReglementPage-section4-mobile{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $primary;
  padding: 10vh 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.InternReglementPage-section4-box{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.InternReglementPage-section4-box-mobile{
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.InternReglementPage-section4-box-mobile2{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.InternReglementPage-divider{
  height: 50px;
}

.InternReglementPage-ul{
  font-family: Raleway-regular, sans-serif;
  list-style: square inside;
  font-size: 18px;
  margin: 10px 0;
}

.InternReglementPage-ol{
  font-family: Raleway-regular, sans-serif;
  list-style: decimal inside;
  font-size: 18px;
  margin: 10px 0;
}

.InternReglementPage-li{
  margin-top: 10px;
}