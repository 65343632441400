.Navigation-list{
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100px;
  align-items: center;
  position: fixed;
  z-index: 5;
  width: 100%;
}
