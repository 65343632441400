$primary: #910505;
$primary-light: #D92528;
$gold: #FCC137;

$gray: #6e798c;
$dark-gray: #2c3038;

$black: #000000;
$white: #ffffff;

$blue: #4D90FE;
$paper: #f1f1f1;