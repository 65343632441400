.ContactPage-section1{
  padding:10% 10%;
  height: 100%;
  display: flex;
  flex-direction: row;
  z-index: 1;
  align-content: space-between;
  justify-content: space-between;
}

.ContactPage-section1-mobile{
  padding: 10% 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.ContactPage-textBox-left{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40vw;
  top: 50%;
  text-align: left;
  margin: 0 0 100px 0;
}

.ContactPage-textBox-left-mobile{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80vw;
  top: 50%;
  text-align: left;
  margin: 50px 0;
}

.ContactPage-imageBox-right{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: left;
  width: 40vw;
  top: 50%;
  margin: 0 0 100px 0;
}

.ContactPage-imageBox-right-mobile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 80vw;
  top: 50%;
  margin: 50px 0;
}

.ContactPage-contactBox{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-start;
  padding: 40px 0 40px 40px;
  width: 30vw;
  clip-path: polygon(
                  0 0,
                  100% 2vw,
                  100% 100%,
                  0 calc(100% - 2vw)
  );
  border: $gold solid 10px;
}

.ContactPage-contactBox-mobile{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-start;
  padding: 40px 0 40px 40px;
  width: 80vw;
  clip-path: polygon(
                  0 0,
                  100% 2vw,
                  100% 100%,
                  0 calc(100% - 2vw)
  );
  border: $gold solid 10px;
}