.Section1-root{
  margin-left: 5%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.Section1-text{
  margin-top: 150px;
  font-size: 50px;
  margin-left: 4px;
  max-width: 100vw;
  color: $dark-gray;
  font-family: 'LemonMilk-Bold', serif;
  z-index: 1;
}

.Section1-text-mobile{
  margin-top: 100px;
  font-size: 5vw;
  margin-left: 4px;
  max-width: 100vw;
  color: $dark-gray;
  font-family: 'LemonMilk-Bold', serif;
  z-index: 1;
}


.Section1-flexbox {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  max-height: 80vh;
  z-index: 1;
}

.Section1-schild{
  position: absolute;
  width: 35%;
  opacity: 0.2;
  right: 100px;
}

.Section1-schild-mobile{
  position: absolute;
  width: 90%;
  max-height: 50%;
  opacity: 0.2;
  top: 50%;
  overflow: hidden;
  object-fit: cover;
  justify-content: flex-end;
  bottom: 0;
  object-position: top;
}

.Section1-transition{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  min-height: 50vh;
  min-width: 100%;
  color: $primary;
  height: fit-content;
  font-family: 'LemonMilk-Medium', serif;
  word-break: keep-all;
  word-wrap: break-word;
  white-space: normal !important;
  position: absolute;
  top: 0%;
}

.Section1-transition-mobile{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  min-height: 50vh;
  min-width: 100%;
  color: $primary;
  height: fit-content;
  font-family: 'LemonMilk-Medium', serif;
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal !important;
  position: absolute;
  top: 0%;
  max-width: 90vw;
}

.text-transition_inner{

}