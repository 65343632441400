.PraesidiaPage-card-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
}

.PraesidiaPage-section1{
  padding:10% 10%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-content: center;
}

.PraesidiaPage-titleBox{
  max-width: 40vw;
  margin-bottom: 150px;
}

.PraesidiaPage-titleBox-mobile{
  max-width: 80vw;
  margin-bottom: 150px;
}

.PraesidiaPage-section2{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $primary;
  padding: 10vh 10%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}


.PraesidiaPage-section2-mobile{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $primary;
  padding: 10vh 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.PraesidiaPage-spacing{
  margin-top: 100px;
}

.PraesidiaPage-schild{
  width: 25vw;
}

.PraesidiaPage-schild-mobile{
  width: 50vw;
}

.PraesidiaPage-image{
  height: 40vh;
  border: 5px solid $gold;
  z-index: 2;
  margin: 10px  10px;
}

.PraesidiaPage-image-mobile{
  height: 60vh;
  width: auto;
  border: 5px solid $gold;
  z-index: 2;
  margin: 10px  10px;
}

.PraesidiaPage-image-subText-left{
  align-self: flex-start;
  font-size: 14px;
  text-align: left;
  margin-left: 5px;
}

.PraesidiaPage-textBox-right{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 40vw;
  top: 50%;
  margin: 100px 0;
}

.PraesidiaPage-textBox-right-mobile{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 80vw;
  top: 50%;
  margin: 50px 0;
}

.PraesidiaPage-subTitle{
  text-align: center;
}
