.weekoverview {
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
  z-index: 1
}

.items {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-start;
  padding: 0 20px 0 20px;
}

.titel2 {
  color: $primary;
  font-size: 50px;
  text-align: center;
  padding-bottom: 40px;
}