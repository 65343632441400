.SubMenu-ul{
  position: absolute;
  background-color: $white;
  width: max-content;
  min-width: 172px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: -1;
  padding-top: 50px;
}

.SubMenu-li {
  font-size: 2rem;
  color: $dark-gray;
  margin: 1rem 1rem;
  border-bottom: 2px solid transparent;
  display: block;
  position: relative;
}

.SubMenu-li:hover {
  border-bottom: 2px solid $gold;
  cursor: pointer;
}
