.SubMenuToggleMobile-button{
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  stroke: $primary;
  fill: $primary;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SubMenuToggleMobile-svg {
  height: 2.5rem;
  outline: none;
  border: none;
  background-color: $white;
  stroke: $primary;
  fill: $primary;
}