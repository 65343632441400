.Section2-root{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $primary;
  padding: 10vh 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.Section2-root-mobile{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $primary;
  padding: 9vh 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}


#section04{
  height: 10vh;
}

#section04 p {
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  scroll-behavior: smooth;
}
#section04 p span{
  position: absolute;
  top: 0;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -12px;
  border-left: 5px solid #fff;
  border-bottom: 5px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb04 2s infinite;
  animation: sdb04 2s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb04 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}
@keyframes sdb04 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}

.Section2-events-title-mobile{
  margin: 0 10%;
  font-size: 4rem;
}

.Section2-eventText{
  text-align: left;
}

.Section2-eventText-mobile{
  text-align: left;
  margin: 0 10%;
}


.Section2-eventText2{
  text-align: left;
  margin-bottom: 100px;
}

.Section2-eventText2-mobile{
  text-align: left;
  margin: 20px 10% 100px 10%
}