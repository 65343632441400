.MenuItem-item{
  font-size: 2.5rem;
  font-weight: bold;
  color: $primary;
  margin: 0 2rem;
  z-index: 5;
}

.MenuItem-soloP{
  border-bottom: 2px solid transparent;
  margin-right: 37px;
  font-size: 30px;
  font-weight: bold;
}

.MenuItem-soloP:hover{
  border-bottom: 2px solid $gold;
  cursor: pointer;
}

.MenuItem-multiP{
  font-size: 30px;
  font-weight: bold;
}

.MenuItem-box{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MenuItem-subBox{
  display: flex;
  flex-direction: row;
  align-items: center;
}