.HamburgerMenu-root {
  overflow: hidden;
}

.HamburgerMenu-background{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: $white;
  z-index: 5;
  pointer-events: none;
}


.HamburgerMenu-background-mobile{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 250px;
  background: $white;
  z-index: 5;
}

