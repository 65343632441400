.HomeTransition-root {
  position: absolute;
  z-index: -50;
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  width: 100vw;
  height: 100vh;
  background-color: $primary;
  overflow: hidden;
}

.HomeTransition-svg {
  position: absolute;
  z-index: -50;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.HomeTransition-pattern {
  color: white
}

.HomeTransition-rectangle {
  width: 100%;
  height: 100%;
  fill: white;
}

.HomeTransition-rect {
  width: 100%;
  height: 100%;
  fill: $white;
  color: $gray;
}

.HomeTransition-text {
  font-size: 10rem;
  line-height: 2.5rem;
  font-weight: bold;
}

.HomeTransition-schildBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.HomeTransition-schild {
  width: 25%;
  height: auto;
}