.NavigationMobile-list{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  align-items: flex-start;
  width: 250px;
  margin-top: 80px;
  position: fixed;
  z-index: 5;
  pointer-events: none;

}

.NavigationMobile-div{
  background: #15171c;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.NavigationMobile-navIcon{
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.NavigationMobile-nav{
  background: #15171c;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  transition: 350ms;
  z-index: 10;
}

.NavigationMobile-yes{
  left: 0;
}

.NavigationMobile-no{
  left: 100%;
}


.NavigationMobile-wrap{
  width: 100%;
}
