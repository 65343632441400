
.Section3-root{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $paper;
  padding: 10rem 10%;
  display: flex;
  flex-direction: column;
  text-align: center;
}


.Section3-root-mobile{
  clip-path: polygon(
                  0 0,
                  100% 6vw,
                  100% 100%,
                  0 calc(100% - 3vw)
  );
  background: $paper;
  padding: 10rem 10%;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.Section3-studentenvereniging{
  display: flex;
  flex-direction: row;
  min-width: 80vw;
  justify-content: space-between;
  align-items: center;
  margin-top: 150px;
}

.Section3-studentenvereniging-mobile{
  display: flex;
  flex-direction: column;
  min-width: 80vw;
  margin-top: 50px;
  align-items: center;
}

.Section3-studentenvereniging-links{
  text-align: left;
  max-width: 40vw;
}

.Section3-studentenvereniging-links-mobile{
  text-align: left;
  max-width: 100%;
}

.Section3-studentenvereniging-rechts{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center;
  padding: 40px 0;
  clip-path: polygon(
                  0 0,
                  100% 2vw,
                  100% 100%,
                  0 calc(100% - 2vw)
  );
  border: $gold solid 10px;
}

.Section3-studentenvereniging-rechts-mobile{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center;
  padding: 40px 10px;
  width: fit-content;
  clip-path: polygon(
                  0 0,
                  100% 2vw,
                  100% 100%,
                  0 calc(100% - 2vw)
  );
  border: $gold solid 10px;
  margin-top: 50px;
}

.Section3-ul{
  list-style: initial;
  font-size: 18px;
  margin: 10px 0;
}
.Section3-ul li{
  margin-left: 35px;
}

.Section3-doelstellingen{
  display: flex;
  flex-direction: row;
  min-width: 80vw;
  justify-content: space-between;
  align-items: center;
  margin-top: 250px;
}

.Section3-doelstellingen-mobile{
  display: flex;
  flex-direction: column-reverse;
  min-width: 80vw;
  justify-content: space-between;
  align-items: center;
  margin-top: 10vh;
}

.Section3-doelstellingen-img{
  width: 70%;
  border: 5px solid $gold;
  transform: rotate(-15deg);
}

.Section3-doelstellingen-img-mobile{
  width: 75%;
  border: 5px solid $gold;
  transform: rotate(-15deg);
}

.Section3-doelstellingen-rechts{
  text-align: left;
  max-width: 40vw;
}

.Section3-doelstellingen-rechts-mobile{
  text-align: left;
}

.Section3-doelstellingen-links{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding: 40px 0;
}

.Section3-doelstellingen-links-mobile{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  padding: 40px 0;
}

.Section3-waarom{
  display: flex;
  flex-direction: row;
  min-width: 80vw;
  justify-content: space-between;
  align-items: center;
  margin-top: 250px;
  margin-bottom: 150px;
}

.Section3-waarom-mobile{
  display: flex;
  flex-direction: column;
  min-width: 80vw;
  justify-content: space-between;
  align-items: center;
  margin-top: 10vh;
  margin-bottom: 50px;
}

.Section3-waarom-links{
  text-align: left;
  max-width: 40vw;
}

.Section3-waarom-links-mobile{
  text-align: left;
}

.Section3-waarom-rechts{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  padding: 40px 0;
}

.Section3-waarom-rechts-mobile{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center;
  padding: 40px 0;
}

.Section3-waarom-img{
  width: 70%;
  border: 5px solid $gold;
  transform: rotate(8deg);
}

.Section3-waarom-imgBox-mobile{
  display: flex;
  justify-content: center;
}

.Section3-waarom-img-mobile{
  width: 75%;
  border: 5px solid $gold;
  transform: rotate(8deg);
  margin: 100px auto;
}


.Section3-waarom-img2{
  width: 70%;
  border: 5px solid $gold;
}

.Section3-waarom-img2-mobile{
  width: 75%;
  border: 5px solid $gold;
  transform: rotate(-8deg) !important;
  margin-top: 50px;
}
