
.main-sponsors{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1 ;
    z-index: 1;
}

.cards-wrapper {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4rem;
    padding: 4rem;
    margin: 0 auto;
    width: max-content;
}

.card2 {
    font-family: 'Heebo';
    --bg-filter-opacity: 0.5;
    background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
    height: 50vh;
    width: 100%;
    font-size: 1.5em;
    color: white;
    border-radius: 1em;
    padding: 1em;
    /*margin: 2em;*/
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 5em -1em black;
    transition: all, var(--transition-time);
    position: relative;
    overflow: hidden;
    border: 10px solid #ccc;
    text-decoration: none;
    margin: 3vw;
}

.card2:hover {
    // transform: rotate(0);
}

.card2 h1 {
    margin: 0;
    font-size: 1.5em;
    line-height: 1.2em;
}

.card2 .tags {
    display: flex;
}

.card2 .tags .tag {
    font-size: 0.75em;
    background: rgba(255,255,255,0.5);
    border-radius: 0.3rem;
    padding: 0 0.5em;
    margin-right: 0.5em;
    line-height: 1.5em;
    transition: all, var(--transition-time);
}

.card2:hover .tags .tag {
    // background: var(--color);
    // color: white;
}

.card2 .date {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.75em;
    padding: 1em;
    line-height: 1em;
    opacity: .8;
}

.card2:before, .card2:after {
    content: '';
    transform: scale(0);
    transform-origin: top left;
    border-radius: 50%;
    position: absolute;
    left: -50%;
    top: -50%;
    z-index: -5;
    transition: all, var(--transition-time);
    transition-timing-function: ease-in-out;
}

.card2:before {
    background: #ddd;
    width: 250%;
    height: 250%;
}

.card2:after {
    background: white;
    width: 200%;
    height: 200%;
}

.card2:hover {
    color: var(--color);
}

.card2:hover:before, .card2:hover:after {
    transform: scale(1);
}

.card2-grid-space .num {
    font-size: 3em;
    margin-bottom: 1.2rem;
    margin-left: 1rem;
}

.info {
    font-size: 1.2em;
    display: flex;
    padding: 1em 3em;
    height: 3em;
}

.info img {
    height: 3em;
    margin-right: 0.5em;
}

.info h1 {
    font-size: 1em;
    font-weight: normal;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1285px) {
    .cards-wrapper {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 900px) {
    .cards-wrapper {
        grid-template-columns: 1fr;
    }
    .info {
        justify-content: center;
    }
    .card2-grid-space .num {
    // /margin-left: 0;
    // /text-align: center;
    }
}

@media screen and (max-width: 500px) {
    .cards-wrapper {
        padding: 4rem 2rem;
    }
    .card2 {
        max-width: calc(100vw - 4rem);
    }
}

@media screen and (max-width: 450px) {
    .info {
        display: block;
        text-align: center;
    }
    .info h1 {
        margin: 0;
    }
}