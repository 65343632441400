.Footer-root {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  pointer-events: auto;
  padding-bottom: 5px;
  padding-top: 40px;
  clip-path: polygon(
                  0 0,
                  100% 2vw,
                  100% 100%,
                  0 calc(100% + 3vw)
  );
}

.Footer-root.Footer-black{
  background-color: $black;
}

.Footer-root.Footer-white{
  background-color: $white;
}

.Footer-root.Footer-primary{
  background-color: $primary;
}

.Footer-root.Footer-paper{
  background-color: $paper;
}

.Footer-copyright{
  text-align: right;
  margin-right: 30px;
  font-weight: bold;
}

.Footer-copyright.Footer-black{
  color: $white;
}

.Footer-copyright.Footer-white{
  color: $primary;
}

.Footer-copyright.Footer-primary{
  color: $white;
}

.Footer-copyright.Footer-paper{
  color: $primary;
}

.Footer-socialBox{
  margin-left: 30px;
}

.MuiSvgIcon-root.Footer-social{
  font-size: 3.8rem;
}
.Footer-social.Footer-paper{
  color: $primary;
  margin: 0 5px;
}

.Footer-social.Footer-paper:hover{
  cursor: pointer;
  color: $black;
}

.Footer-social.Footer-primary{
  color: $white;
  margin: 0 5px;
}

.Footer-social.Footer-primary:hover{
  cursor: pointer;
  color: $black;
}

.Footer-social.Footer-black{
  color: $white;
  margin: 0 5px;
}

.Footer-social.Footer-black:hover{
  cursor: pointer;
  color: $primary;
}

.Footer-social.Footer-white{
  color: $primary;
  margin: 0 5px;
}

.Footer-social.Footer-white:hover{
  cursor: pointer;
  color: $black;
}
