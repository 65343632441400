.root {
  background: $paper;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  height: available;
  max-width: 340px;
  padding: 1em;
  margin: 20px;
  z-index: 4;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
  :hover :focus{
    opacity: 0.3
  }
}

.image {
  margin-top: -20px;
  width: calc(100% + 2em);
  height: calc(40% + 2em);
  min-height: 40%;
  border-radius: 4px 4px 0 0;
}

.title{
  width: 100%;
  line-height: 32px;
  margin-top: 0;
  padding: 0 1em;
  color: $dark-gray;
  font-size: 25px;
  text-align: center;
}

.description {
  font-weight: 400;
  font-size: 16px;
  color: $gray;
  margin-top: 10px;
}

.buttonBox{
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  margin-top: auto;
  margin-bottom: 6px;
}

.datetime-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.datetime{
  width: 100%;
  line-height: 25px;
  margin-top: 5px;
  font-size: 15px;
  text-align: center;
}

.priceLocation{
  width: 100%;
  line-height: 25px;
  margin-top: 5px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
.box {
  min-height: 350px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}